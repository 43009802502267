<template>
  <DialogFormWrapper
    ref="dialogWrapper"
    class="file-add"
  >
    <template #form>
      <FileDragDrop
        v-model="files"
        :output-formats="['jpeg', 'jpg', 'pdf', 'png']"
        @error="showErrors"
      />
    </template>

    <template #submit>
      <v-btn
        v-if="!isUploadCompleted"
        :disabled="!files.length"
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="sendFiles"
        @click="sendFiles"
      >
        Załącz plik
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FileDragDrop from './Inputs/FileDragDrop'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    FileDragDrop,
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      isFormProcessing: false,
      isUploadCompleted: false,
      files: []
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.order.isProcessing
    }),
    file: {
      get () {
        return this.files[0]
      },
      set (value) {
        if (value?.[0] && (value[0].status === 'uploaded' || value[0].status === 'error')) {
          this.isFormProcessing = false
          this.isUploadCompleted = true
        }
      }
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      uploadBDOFile: 'order/uploadBDOFile'
    }),

    showErrors (errors) {
      this.showSnackbar({
        message: errors,
        type: 'error'
      })
    },

    sendFiles () {
      this.isFormProcessing = true

      const formData = new FormData()
      formData.append('file', this.file.data)
      this.file.status = 'processing'

      this.uploadBDOFile(formData)
        .then(() => {
          this.afterFormSubmitted('Karta KPO/KPOK dodana')
        })
        .catch(() => {
          this.file.status = 'error'
        })
    }
  }
}
</script>
